<script>
import { Bar, mixins } from 'vue-chartjs';
import { Chart } from 'chart.js';
const { reactiveProp } = mixins;

Chart.Tooltip.positioners.custom = function (elements) {
	const { x, y, base } = elements[0]._model;
	const height = base - y;
	return { x, y: y + (height / 2) };
}

export default {
	extends: Bar,
	mixins: [reactiveProp],
	props: ['chart-data', 'options'],
	mounted() {
		// this.chartData is created in the mixin.
		// If you want to pass options please create a local options object
		this.renderChart(this.chartData, this.options);
	}
}
</script>

<style>
</style>