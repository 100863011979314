<template>
	<b-container fluid :id="'remediatedWidget_'+rem">
		<b-row class="m-0 center mb-2"> 
			<b-col cols="auto px-0 my-2" style="margin-right: -2px;">
				<div class="y-axis bar-chart"></div>
			</b-col>
			<b-col cols="10" class="px-0">
				<cg-horizontal-bar-chart
					:chart-data="chartData"
					:options="options"
					:height="100"
					:ref="'chart_'+rem"
				></cg-horizontal-bar-chart>
			</b-col>
			<b-col cols="2" class="px-0" v-if="remediationDetails.includes(type)" align="end">
				<b-button variant="darknavy" size="md" class="mx-2 rem-light-details" v-b-modal="'remediationDetailsModal_'+this.rem">
					{{ $t('Remediation.Details') }}
				</b-button>
			</b-col>
		</b-row>

		<b-modal size="xl" :id="'remediationDetailsModal_'+this.rem" :title="$t('Remediation.Details')" body-class="px-0 py-0 modal-body-full-width remediated-widget">
			<b-tabs card>
				<b-tab :title="$t(getTabTitle())" v-if="this.$checkPermission('view-target-risk')" active>
					<cg-analytics-table :data="target" variant="dawn"></cg-analytics-table>
				</b-tab>
				<b-tab :title="$t('Navbar.Template')">
					<cg-analytics-table :data="template" variant="forest"></cg-analytics-table>
				</b-tab>
			</b-tabs>
			
			<template #modal-footer>
				<b-button :href="reportLink" variant="primary" v-if="type <= TEMPLATE_REM_LIGHT" :click="createRemReport()">{{$t('Dashboard.CreateReport')}}</b-button>
				<b-button :href="exportLink" variant="primary" :disabled="target && !target.totalRows" :click="createRemExport()">{{$t('General.Data')}}</b-button>
				<b-button @click="closeRemModal" variant="secondary">{{$t('General.Close')}}</b-button>
			</template>
		</b-modal>
		<h5 class="my-0">
			<animated-counter :to="improved"></animated-counter>&nbsp;{{ $t('Remediation.Remediated' + type, { param: targets }) }}
		</h5>
	</b-container>
</template>

<script>
import HorizontalBarChart from "@/components/charts/wrappers/HorizontalBarChart";
import colors from "@/components/charts/utils/colors";
import AnimatedCounter from "@/components/AnimatedCounter";
import AnalyticsTable from "@/components/tables/AnalyticsTable";
import campaignService from "@/services/campaign.service";
import syspropService from "../../../services/sysprop.service.js";
import { Campaign, TemplateType } from '../../../../../server/common/constants.js';

export default {
	components: {
		"cg-horizontal-bar-chart": HorizontalBarChart,
		AnimatedCounter,
		"cg-analytics-table": AnalyticsTable
	},
	data() {
		return {
			inProgress: true,
			sortBy: "click_rate",
			sortDesc: true,
			remediationDetails: [],
			context_target: {
				searchText: "",
				sortBy: "click_rate",
				sortDesc: true
			},
			chartData: {},
			options: {},
			improved: 0,
			template: {},
			target: {},
			targets: 0,
			reportLink: '',
			exportLink: '',
			TEMPLATE_REM_LIGHT: null
		}
	},
	props: {
		data: Array,
		type: Number,
		rem: String,
		companyLan: String
	},
	mounted() {
		this.inProgress = true;
		this.refresh_ui();

		Promise.all([this.getTemplate(), this.getResult()]).then(values => {
			// Completed remediation array contains [ template_result, target_result/click_result ]
			this.template = values[0];
			this.result = values[1];

			this.prepareTemplateData(this.template);
			this.prepareResultData(this.result);
		}).catch(error => {
			console.error("RemediatedWidget - Promise all - Template & Target/Clicks - Error", error);
		}).finally(() => {
			this.inProgress = false;
		});
	},
	created() {
		this.remediationDetails = [
			Campaign.Type.RemediationLight, 
			Campaign.Type.RemediationTraining, 
			Campaign.Type.RemediationQRCode, 
			Campaign.Type.RemediationUSB 
		];

		this.TEMPLATE_REM_LIGHT = Campaign.Type.RemediationLight;
	},
	methods: {
		closeRemModal() {
			this.$bvModal.hide('remediationDetailsModal_'+this.rem);
		},
		getTabTitle() {
			return [Campaign.Type.RemediationQRCode, Campaign.Type.RemediationUSB].includes(this.type)? 'Remediation.Clicks' : 'Navbar.Targets'; 
		},
		async getTemplate() {
			const response = await campaignService.getCompletedRemediationTemplates(this.rem);
			return response && response.data;
		},
		async getResult() {
			let response = null;

			if([Campaign.Type.RemediationQRCode, Campaign.Type.RemediationUSB].includes(this.type)) {
				response = await campaignService.getCompletedRemediationClicks(this.rem);
			} else {
				response = await campaignService.getCompletedRemediationTargets(this.rem);
			}
			
			return response && response.data;
		},
		refresh_ui() {
			this.setData();
			this.setOptions();

			this.$forceUpdate();
		},
		prepareTemplateData(templateData) {
			this.templateLabel = {
				[TemplateType.Email] : this.$t('Analytics.TemplateTypes.Email'),
				[TemplateType.SMS] : this.$t('Analytics.TemplateTypes.Sms'),
				[TemplateType.QRCode] : this.$t('Analytics.TemplateTypes.QRCode'),
				[TemplateType.USB] : this.$t('Analytics.TemplateTypes.USB'),
				[TemplateType.Training] : this.$t('Analytics.TemplateTypes.Training')
			};

			templateData.forEach( (row) => {
				row.click_rate = row.sent > 0 ? (100 * (row.clicked / row.sent)).toFixed(1) + "%" : 0 + "%";
				row.used_last = row.used_last == 1 ? this.$t('Analytics.Table.Yes') : this.$t('Analytics.Table.No');
				row.type = this.templateLabel[row.type] || this.$t("Analytics.NA");
			});

			let fields = [
				{ key: 'name', label: this.$t('Analytics.Table.Name'), sortable: true },
				{ key: 'type', label: this.$t('Analytics.Type'), sortable: true, class: 'text-center' },
			];

			if(this.type == Campaign.Type.RemediationLight) {
				fields.push(
					{ key: 'sent', label: this.$t('Analytics.Table.Sent'), sortable: true, class: 'text-center' },
					{ key: 'clicked', label: this.$t('Analytics.Table.Clicked'), sortable: true, class: 'text-center' },
					{ key: 'click_rate', label: this.$t('Analytics.Table.ClickRate'), sortable: true, class: 'text-center' }
				);
			} else if(this.type == Campaign.Type.RemediationTraining) {
				fields.push(
					{ key: 'sent', label: this.$t('Analytics.Table.Sent'), sortable: true, class: 'text-center' },
					{ key: 'clicked', label: this.$t('Analytics.Table.ClassStarted'), sortable: true, class: 'text-center' },
					{ key: 'submitted_data', label: this.$t('Analytics.Table.ClassEnded'), sortable: true, class: 'text-center' }
				);
			} else {
				fields.push(
					{ key: 'clicked', label: this.$t('Analytics.Table.Clicked'), sortable: true, class: 'text-center' }
				);
			}

			this.template = {
				primary_key: "template_id",
				ref_id: "rem_template",
				paginated: true,
				items: templateData,
				fields: fields,
				sortBy: this.sortBy,
				sortDesc: this.sortDesc,
				totalRows: templateData.length,
				perPage: 10,
				currentPage: 1
			};
		},
		async createRemReport() {
			let config = await syspropService.getConfig();

			let reportConfig = config.data.defaults.report;
			let language = this.$account.language || this.companyLan || reportConfig.lang || this.$i18n.locale;
			this.reportLink = `api/campaign/${this.rem}/report?lang=${language}`;
		},
		createRemExport() {
			let companyId = localStorage.getItem("cg-company");
			this.exportLink = `api/company/${companyId}/export?campaignId=${this.rem}`;
		},
		prepareResultData(resultData) {
			if([Campaign.Type.RemediationQRCode, Campaign.Type.RemediationUSB].includes(this.type)) {
				this.prepareClickData(resultData);
			} else {
				this.prepareTargetData(resultData);
			}
		},
		prepareClickData(clickData) {
			let fields = [
				{ key: 'campaign_name', label: this.$t('Analytics.Table.Campaign'), sortable: true },
				{ key: 'rid', label: this.$t('Analytics.Table.Rid'), sortable: true, class: 'text-center' },
				{ key: 'clicked', label: this.$t('Analytics.Table.Time'), sortable: true, class: 'text-center' },
				{ key: 'phished_ip', label: this.$t('Analytics.Table.Details'), sortable: true, class: 'text-center' }
			];

			if(this.type == Campaign.Type.RemediationUSB) {
				fields.push(
					{ key: 'machine_name', label: this.$t('Analytics.Table.Machine'), sortable: true, class: 'text-center' },
					{ key: 'machine_ip', label: this.$t('Analytics.Table.IPs'), sortable: true, class: 'text-center' }
				);
			} else if(this.type == Campaign.Type.RemediationQRCode) {
				fields.push(
					{ key: 'user_data', label: this.$t('Analytics.Table.Info'), sortable: true, class: 'text-center' }
				);
			}

			this.target = {
				ref_id: "rem_targets",
				paginated: true,
				items: clickData,
				fields: fields,
				sortBy: this.context_target.sortBy,
				sortDesc: this.context_target.sortDesc,
				totalRows: clickData.length,
				perPage: 10,
				currentPage: 1
			};

		},
		prepareTargetData(targetData) {
			targetData.forEach((row) => {
				row.click_rate = row.click_rate? row.click_rate.toFixed(1) + "%" : null;
				row.clicked_last = row.clicked_last == 1? this.$t('Analytics.Table.Yes') : this.$t('Analytics.Table.No');

				// Task 1336 - anonymise Target personal data according the given permissions
				if(!this.$checkPermission('view-target-details')) {
					row.name = this.$t('Analytics.Table.Target') + "_" + row.target_id;
				}

				if(typeof row.risk_group != 'number') {
					row.risk_group = this.$t('Analytics.Table.UnratedClickers');
				} else {
					switch(row.risk_group) {
						case 1:
							row.risk_group = this.$t('Analytics.Table.SerialClickers');
							break;
						case 2:
							row.risk_group = this.$t('Analytics.Table.FrequentClickers');
							break;
						case 3:
							row.risk_group = this.$t('Analytics.Table.RareClickers');
							break;
						case 4:
							row.risk_group = this.$t('Analytics.Table.RareClickers');
							break;
						default:
							row.risk_group = this.$t('Analytics.Table.UnratedClickers');
							break;
					}
				}
			});

			let fields = [];

			switch(this.type) {
				case Campaign.Type.RemediationTraining:
					fields = [
						{ key: 'name', label: this.$t('Analytics.Table.Target'), sortable: true },
						{ key: 'org_1', label: this.$t('Analytics.Table.Org'), sortable: true, class: 'text-center' },
						{ key: 'sent', label: this.$t('Analytics.Table.Sent'), sortable: true, class: 'text-center' },
						{ key: 'clicked', label: this.$t('Analytics.Table.ClassStarted'), sortable: true, class: 'text-center' },
						{ key: 'submitted_data', label: this.$t('Analytics.Table.ClassEnded'), sortable: true, class: 'text-center' },
						{ key: 'subject', label: this.$t('Analytics.Table.LastTemplateSubject'), sortable: true, class: 'd-none d-md-table-cell text-center' }
					];
					break;
				case Campaign.Type.RemediationLight:
				default:
					fields = [
						{ key: 'name', label: this.$t('Analytics.Table.Target'), sortable: true },
						{ key: 'org_1', label: this.$t('Analytics.Table.Org'), sortable: true, class: 'text-center' },
						{ key: 'sent', label: this.$t('Analytics.Table.Sent'), sortable: true, class: 'text-center' },
						{ key: 'clicked', label: this.$t('Analytics.Table.Clicked'), sortable: true, class: 'text-center' },
						{ key: 'click_rate', label: this.$t('Analytics.Table.ClickRate'), sortable: true, class: 'text-center' },
						{ key: 'risk_group', label: this.$t('Analytics.Table.RiskGroup'), sortable: true, class: 'text-center' },
						{ key: 'subject', label: this.$t('Analytics.Table.LastTemplateSubject'), sortable: true, class: 'd-none d-md-table-cell text-center' }
					];
					break;
			}

			this.target = {
				ref_id: "rem_targets",
				paginated: true,
				items: targetData,
				fields: fields,
				sortBy: this.context_target.sortBy,
				sortDesc: this.context_target.sortDesc,
				totalRows: targetData.length,
				perPage: 10,
				currentPage: 1
			};

		},
		setData() {
			this.targets = this.data[0] || 0;
			this.improved = this.data[1] || 0;

			this.chartData = {
				labels: [""],
				datasets: [
					{
						data: [this.improved],
						backgroundColor: colors.alpha.green,
						borderColor: colors.plain.green,
						borderWidth: 1
					},
					{
						data: [this.targets - this.improved],
						backgroundColor: colors.alpha.grey,
						borderColor: colors.alpha.grey,
						borderWidth: 1
					}
				]
			}
		},
		setOptions() {
			this.options = {
				layout: {
					padding: {
						left: -20,
						top: 20,
						bottom: -10
					}
				},
				scales: {
					xAxes: [
						{
							gridLines: {
								display: false
							},
							stacked: true,
							ticks: {
								callback: function () {
									return '';
								}
							}
						}
					],
					yAxes: [
						{
							stacked: true,
							gridLines: {
								display: false
							}
						}
					]
				},
				legend: {
					display: false
				},
				responsive: true,
				maintainAspectRatio: false,
				animation: {
					animateRotate: true,
					animateScale: true
				},
				tooltips: {
					enabled: false
				},
				plugins: {
					stacked100: { enable: true, replaceTooltipLabel: false },
					datalabels: {
						formatter: (_value, context) => {
							if(this.type == Campaign.Type.RemediationQRCode || this.type == Campaign.Type.RemediationUSB) return '';

							const data = context.chart.data;
							const { datasetIndex, dataIndex } = context;
							return datasetIndex == 0 && _value > 0 ? data.calculatedData[datasetIndex][dataIndex].toFixed(1) + "%" : "";
						},
						color: colors.plain.primary,
						textAlign: "center",
						font: {
							size: 12
						}
					},
					labels: {
						fontSize: 0
					}
				}
			};
		}
	}
};
</script>

<style lang="less">
.y-axis {
	background: rgb(222, 226, 230);
	height: 100%;
	width: 2px;
	margin: auto 0;
	position: relative;
}
div[id^="remediationDetailsModal_"] {
	overflow-y: hidden !important;
}
.remediated-widget .b-table-sticky-header {
	max-height: 552px !important;
}
.rem-light-details {
	margin: 31px auto;
}
</style>