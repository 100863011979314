<script>
import { HorizontalBar, mixins } from 'vue-chartjs'
import { Chart } from 'chart.js';
const { reactiveProp } = mixins

Chart.Tooltip.positioners.custom = function (elements) {
	const { y, x, base } = elements[0]._model;
	const width = base - x;
	return { y, x: x + (width / 2) };
}

export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: ['chart-data', 'options'],
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options)
  }
}
</script>

<style>
</style>