import axios from '../plugins/axios.js'

class AccountService {

	createAccount(data) {
		return axios.post("/account", data);
	}

	updateAccount(data) {
		return axios.put("/account", data);
	}

	getAccounts(accountId, searchText, page, numRes, sortBy, sortDesc) {
		let opt = { params: { searchText: searchText, page: page, numRes: numRes, sortBy: sortBy, sortDesc: sortDesc } };
		return axios.get(`/account/${accountId}`, opt);
	}

	getAccountRoles(accountId, companyId) {
		return axios.get(`/account/${accountId}/roles`, { params: { companyId: companyId } });
	}

	updateAccountRoles(accountId, data) {
		return axios.put(`/account/${accountId}/roles`, data);
	}

	getAccountCompanies(accountId) {
		return axios.get(`/account/${accountId}/companies`);
	}

	getAccountPermissions(accountId, companyId) {
		return axios.get(`/account/${accountId}/permissions`, { params: { companyId: companyId } });
	}

	updateAccountById(accountId, data) {
		return axios.put(`/account/${accountId}`, data);
	}

	deleteAccount(accountId) {
		return axios.delete(`/account/${accountId}`);
	}

	changePassword(accountId, data) {
		return axios.post(`/account/${accountId}/password`, data);
	}

	resetPassword(accountId, data) {
		return axios.post(`/account/${accountId}/reset`, data);
	}

	getCampaignApprovers(companyId) {
		return axios.get(`/account/approvers`, { params: { companyId: companyId } });
	}
	
}

export default new AccountService();