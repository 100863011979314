const colors = {
  plain: {
    white: "rgb(255, 255, 255)",
    black: "rgb(0, 0, 0)",
    primary: "rgb(17, 28, 57)",
    secondary: "rgb(255, 151, 18)",
    blue: "rgb(122, 186, 255)",
    grey: "rgb(140, 140, 140)",
    green: "rgb(141, 204, 191)",
    yellow: "rgb(243, 191, 126)",
    red: "rgb(249, 174, 167)",
    darkRed: "rgb(240, 91, 79)",
    darkGrey: "rgb(145, 149, 158)"
  },
  alpha: {
    blue: "rgba(0, 123, 255, 0.31)",
    lightBlue: "rgba(0, 123, 255, 0.08)",
    grey: "rgba(221, 221, 221, 0.72)",
    lightGrey: "rgba(221, 221, 221, 0.08)",
    green: "rgba(26, 188, 156, 0.38)",
    lightGreen: "rgba(26, 188, 156, 0.08)",
    yellow: "rgba(255, 151, 18, 0.38)",
    lightYellow: "rgba(255, 151, 18, 0.08)",
    red: "rgba(240, 91, 79, 0.38)",
    lightRed: "rgba(240, 91, 79, 0.08)",
    darkGrey: "rgba(17, 28, 57, 0.38)"
  },
  palette: {
    dark: [
      "rgba(0, 123, 255, 0.31)",
      "rgba(26, 188, 156, 0.38)",
      "rgba(240, 91, 79, 0.38)",
      "rgba(255, 151, 18, 0.38)",
      "rgba(221, 78, 167, 0.38)",
      "rgba(192, 176, 255, 0.38)",
      "rgba(132, 55, 112, 0.38)"
    ],
    light: [
      "rgba(0, 123, 255, 0.08)",
      "rgba(26, 188, 156, 0.08)",
      "rgba(240, 91, 79, 0.08)",
      "rgba(255, 151, 18, 0.08)",
      "rgba(221, 78, 167, 0.08)",
      "rgba(192, 176, 255, 0.08)",
      "rgba(132, 55, 112, 0.08)"
    ],
    riskGroup: {
      plain: {
        dark: [
          "rgb(122, 186, 255)",
          "rgb(141, 204, 191)",
          "rgb(243, 191, 126)",
          "rgb(249, 174, 167)"
        ]
      },
      alpha: {
        dark: [
          "rgba(0, 123, 255, 0.31)",
          "rgba(26, 188, 156, 0.38)",
          "rgba(255, 151, 18, 0.38)",
          "rgba(240, 91, 79, 0.38)"
        ],
        light: [
          "rgba(0, 123, 255, 0.08)",
          "rgba(26, 188, 156, 0.08)",
          "rgba(255, 151, 18, 0.08)",
          "rgba(240, 91, 79, 0.08)"
        ]
      }
    }
  }
};

export default colors;