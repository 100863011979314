<template>
	<span>{{formattedValue}}</span>
</template>

<script>
export default {
	data: function () {
		return {
			counter: 0,
			step: 0
		};
	},
	props: {
		to: Number,
		precision: {
			type: Number,
			default: 0
		},
		duration: {
			type: Number,
			default: 1,
		}
	},
	watch: {
		to() {
			this.counter = 0;
			this.step = this.to / (this.duration * 100);
			this.updateCount();
		}
	},
	computed: {
		formattedValue() {
			return Number(this.counter.toFixed(this.precision)).toLocaleString();
		}
	},
	methods: {
		updateCount() {
			// Check if to is reached
			if (this.counter < (this.to - this.step)) {
				this.counter = this.counter + this.step;
				setTimeout(this.updateCount);
			} else {
				this.counter = this.to;
			}
		}
	}
}
</script>