<template>
	<b-card id="remediationWidget" no-body>

		<b-card-header>
			<span class="remediation-title">{{ title }}</span>
			<span class="text-uppercase font-weight-bold remediation-status" v-if="statusRunning">{{ label }}</span>
			<b-button class="float-right" :disabled="disableRemediation" @click="remediate" variant="primary" size="sm" v-else>{{ label }}</b-button>
		</b-card-header>

		<b-row class="mx-2 mt-4" align-v="center">
			<b-col md="6">
				<!-- Weak Remediation -->
				<cg-bar-chart v-if="type==REM_WEAK"
					:chart-data="chartData"
					:options="options"
					:height="180"
					ref="chart_3"
				></cg-bar-chart>
				<div v-if="type==REM_WEAK" class="x-axis bar-chart"></div>

				<!-- Worsened Remediation -->
				<cg-doughnut-chart v-if="type==REM_WORSEN"
					:chart-data="chartData"
					:options="options"
					:height="180"
					ref="chart_4"
				></cg-doughnut-chart>

				<!-- Top Clickers -->
				<div v-if="type==REM_CLICKERS" >
					<table class="table table-bordered table-topclicker">
						<thead> 
							<tr>
								<th class="th-topclicker"> 
									<font-awesome-icon :icon="['fas', 'sort-amount-down-alt']"/>
									<span style="font-weight: 60;">{{ $t("Remediation.ClickRate") }}</span>
								</th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="i in (topclick.data[0])" :key="i" :style="'background-color:' + topclick.backgroundColors[0]">
								<td v-if="i<(topclick.data[0])"></td>
								<td v-if="i===(topclick.data[0])" :style="'border-bottom: 0.5px solid ' + topclick.colorSeparator"></td>
							</tr>
							<tr v-for="j in (topclick.data[1])" :key="j+10" :style="'background-color:' + topclick.backgroundColors[1]">
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>

				<!-- Remediation Light -->
				<div v-if="type==REM_LIGHT">
					<div class="remediation-wrapper mb-4">
						<div class="remediation-light-circle">
							<div class="remediation-icon-wrapper user">
								<font-awesome-icon class="remediation-icon user" :icon="['fas', 'users']" />
							</div>
							<div class="remediation-icon-wrapper other">
								<font-awesome-icon class="remediation-icon calendar" :icon="['fas', 'calendar-alt']" />
								<font-awesome-icon class="remediation-icon email" :icon="['fas', 'envelope-open-text']" />
							</div>
						</div>
					</div>
				</div>

				<!-- Training Remediation -->
				<div v-if="type==REM_TRAINING">
					<div class="remediation-wrapper d-flex justify-center mb-4">
						<font-awesome-icon class="remediation-single-icon" :icon="['fas', 'chalkboard-teacher']" />
					</div>
				</div>

				<!-- QR Code Remediation -->
				<div v-if="type==REM_QRCODE">
					<div class="remediation-wrapper d-flex justify-center mb-4">
						<font-awesome-icon class="remediation-single-icon" :icon="['fas', 'qrcode']" />
					</div>
				</div>

				<!-- USB Remediation -->
				<div v-if="type==REM_USB">
					<div class="remediation-wrapper d-flex justify-center mb-4">
						<font-awesome-icon class="remediation-single-icon" :icon="['fab', 'usb']" />
					</div>
				</div>
			</b-col>
			
			<b-col md="6">
				<span class="align-middle">{{ $t("Remediation.Text_" + type, {param: (type == REM_CLICKERS? data.dataset[1]*100 + "%" : data.dataset[1]) }) }}</span>
			</b-col>
		</b-row>

		<b-row class="mx-4" align-h="end">
			<span v-if="sendingProgress" class="font-weight-bold font-14">{{ sendingProgress }}</span>
		</b-row>
		<b-row class="mx-4 mb-2">
			<span v-if="statusRunning" class="font-italic">{{ $t('Remediation.PlannedEnd', { param: plannedEnd }) }}</span>
		</b-row>
	</b-card>
</template>

<script>
import BarChart from "@/components/charts/wrappers/BarChart";
import DoughnutChart from "@/components/charts/wrappers/DoughnutChart";
import colors from "@/components/charts/utils/colors";
import { Campaign } from '../../../../../server/common/constants';

export default {
	components: {
		"cg-bar-chart": BarChart,
		"cg-doughnut-chart": DoughnutChart
	},
	data() {
		return {
			chartData: {},
			options: {},
			map: [],
			topclick: [],
			total: 0,
			fraction: 0,
			REM_WEAK: null,
			REM_WORSEN: null,
			REM_CLICKERS: null,
			REM_LIGHT: null,
			REM_QRCODE: null,
			REM_USB: null,
			REM_TRAINING: null
		};
	},
	computed: {
		disableRemediation() {
			if(!this.$checkPermission('approve-campaign')) return true;
			else if([this.REM_LIGHT, this.REM_QRCODE, this.REM_USB].includes(this.type)) return false;
			else return this.data && this.data.targets && this.data.targets.length == 0;
		},
		title() {
			if([this.REM_LIGHT, this.REM_QRCODE, this.REM_TRAINING, this.REM_USB].includes(this.type)) return this.$t("Remediation." + this.type);

			let param = this.data && this.type == this.REM_CLICKERS? this.data.dataset[1]*100 + "%" : this.data.dataset[1];
			return this.$t("Remediation." + this.type, { param });
		},
		statusRunning() {
			return this.data && this.data.status ? this.data.status > 1 && this.data.status < 5 : null;
		},
		label() {
			return this.data && this.data.status > 1 && this.data.status < 5 ? this.$t("Remediation.Running") : this.$t("Remediation.Initialize");
		},
		plannedEnd() {
			return this.data && this.data.planned_end? this.$moment.utc(this.data.planned_end).local().locale(this.$i18n.locale).fromNow() : "";
		},
		sendingProgress() {
			// Explicit null values are required to alert about an error occurred somewhere in the backend summary query
			if([Campaign.Status.Starting, Campaign.Status.Running].includes(this.data.status) && [this.REM_LIGHT, this.REM_TRAINING].includes(this.data.type)) {
				let sent = this.data && this.data.dataset.length && typeof this.data.dataset[1] == 'number' ? this.data.dataset[1] : 'null';
				let toSend = this.data && this.data.dataset.length && typeof this.data.dataset[0] == 'number' ? this.data.dataset[0] : 'null';
				return this.$t("Remediation.MailSendingProgress", { sent: sent, toSend: toSend });
			} else return '';
		}
	},
	props: {
		data: Object,
		type: Number
	},
	created() {
		this.REM_WEAK = Campaign.Type.RemediationWeak;
		this.REM_WORSEN = Campaign.Type.RemediationWorsen;
		this.REM_CLICKERS = Campaign.Type.RemediationClickers;
		this.REM_LIGHT = Campaign.Type.RemediationLight;
		this.REM_QRCODE = Campaign.Type.RemediationQRCode;
		this.REM_USB = Campaign.Type.RemediationUSB;
		this.REM_TRAINING = Campaign.Type.RemediationTraining;
		
		if(this.type == this.REM_CLICKERS) {
			this.setTopClickers();
		}
	},
	mounted() {
		this.refresh_ui();
	},
	methods: {
		refresh_ui() {
			if([this.REM_LIGHT, this.REM_QRCODE, this.REM_TRAINING, this.REM_USB].includes(this.type)) return;
			this.setData();
			this.setOptions();

			this.$forceUpdate();
		},
		remediate() {
			this.$emit('remediate', this.type);
		},
		setData() {
			this.total = this.data.dataset[0] - this.data.dataset[1];
			this.fraction = this.data.dataset[1];

			switch(this.type) {
				case this.REM_WEAK:
					this.chartData = {
						labels: [""],
						datasets: [
							{
								data: [this.total],
								backgroundColor: colors.alpha.blue,
								borderColor: colors.plain.blue,
								borderWidth: 1
							},
							{
								data: [this.fraction],
								backgroundColor: colors.alpha.red,
								borderColor: colors.alpha.red,
								borderWidth: 1
							}
						]
					}
					break;
				case this.REM_WORSEN:
					this.chartData = {
						labels: [""],
						datasets: [
							{
								data: [this.total, this.fraction],
								backgroundColor: [colors.alpha.blue, colors.alpha.red],
								borderColor: [colors.plain.blue, colors.plain.red],
								borderWidth: 1
							}
						]
					};
					break;
				default:
					return;
			}
		},
		setOptions() {
			switch(this.type) {
				case this.REM_WEAK:
					this.options = {
						layout: {
							padding: {
								left: -20
							}
						},
						scales: {
							xAxes: [
								{
									gridLines: {
										display: false
									},
									stacked: true,
									ticks: {
										callback: function () {
											return '';
										}
									}
								}
							],
							yAxes: [
								{
									display: false,
									stacked: true,
									gridLines: {
										display: false
									},
									ticks: {
										display: false
									}
								}
							]
						},
						legend: {
							display: false
						},
						responsive: true,
						maintainAspectRatio: false,
						animation: {
							animateRotate: true,
							animateScale: true
						},
						tooltips: {
							enabled: false
						},
						plugins: {
							datalabels: {
								formatter: (_value) => {
									return _value;
								},
								color: colors.plain.primary,
								textAlign: "center",
								font: {
									size: 12
								}
							},
							labels: {
								fontSize: 0
							}
						}
					};
					break;
				case this.REM_WORSEN:
					this.options = {
						layout: {
							padding: {
								bottom: 30
							}
						},
						legend: {
							display: false
						},
						responsive: true,
						maintainAspectRatio: false,
						animation: {
							animateRotate: true,
							animateScale: true
						},
						tooltips: {
							enabled: false
						},
						cutoutPercentage: 0,
						plugins: {
							datalabels: {
								formatter: (_value) => {
									const sum = this.data.dataset[0];
									const percentage = (100 * _value / sum).toFixed(1);
									return percentage > 0 && !isNaN(percentage) ? percentage + "%" : "";
								},
								display: (context) => {
									const data = context.dataset.data;

									// Hide labels in PieChart when the value to be shown is too little for the slice to contain
									const { dataIndex } = context;
									const sum = this.data.dataset[0];
									let calculatedData = data.slice();

									const percentage = (100 * calculatedData[dataIndex] / sum).toFixed(1);

									// Empiric value choosen to display the value plus the "%" character in the basic PieChart
									return percentage >= 6;	
								},
								color: colors.plain.primary,
								font: {
									size: 12
								}
							}
						}
					}
					break;   
				default:
					return;
			}
		},
		setTopClickers() { 
			this.topclick = { 
				backgroundColors: [colors.alpha.blue, colors.alpha.red],
				data: [10-Math.trunc(this.data.dataset[1]*10), Math.trunc(this.data.dataset[1]*10)],
				colorSeparator: colors.plain.blue,
				color: colors.plain.primary
			}
		}
	}
};
</script>

<style lang="less">
#remediationWidget {
	min-height: 310px;
	.remediation-title {
		font-size: 18px; 
		font-weight: bold; 
		vertical-align: sub;
	}
	.remediation-status {
		float: right; 
		line-height: 28px;
	}
	.x-axis {
		background: rgb(222, 226, 230);
		height: 2px;
		width: 100%;
		margin: 0 auto;
		position: relative;
		&:before,
		&:after {
			content: "";
			background: rgb(222, 226, 230);
			position: absolute;
			height: 2px;
			width: 5px;
		}
		&:before {
			bottom: -1px;
			right: -3px;
			transform: rotate(-45deg);
		}
		&:after {
			top: -1px;
			right: -3px;
			transform: rotate(45deg);
		}
	}
	.bar-chart {
		top: -29px;
	}
	.table-topclicker {
		top: 0px;
		margin-top: .01rem;
		margin-left: 30px;
		max-width: 75%;
	}
	.th-topclicker {
		background-color: rgba(221, 221, 221, 0.72);
		font-size: 12px;
		padding: 2px 0 0 5px;
	}
	.remediation-wrapper {
		position: relative;
		margin: 0 auto;
	}
	.remediation-single-icon {
		height: 120px;
		width: 120px;
		margin: 30px auto 0;
	}
	.remediation-light-circle {
		height: 120px;
		width: 120px;
		border-radius: 50%;
		border: 4px solid lightgray;
		margin: 30px auto 0;
	}
	.remediation-icon-wrapper {
		height: 40px;
		width: 40px;
		&.user {
			margin: -18px auto 0;
		}
		&.other {
			margin-top: 36px;
			display: inline-flex;
			justify-content: space-between;
			width: 150px;
			margin-left: -22px;
		}
	}
	.remediation-icon {
		background: white;
		border-radius: 50%;
		height: 40px;
		width: 40px;
		&.user {
			padding: 2px;
		}
		&.calendar, &.email {
			margin: 2px;
			padding: 4px;
			border-radius: 10%;
		}
	}
}
</style>
